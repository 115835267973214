import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[bcsButtonIcon]'
})
export class ButtonIconDirective implements OnInit {

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit(): void {
    const css = `
      font-size: 14px;
      height: 14px;
      width: 14px;
      margin-left: 8px;
    `
    this.renderer.setAttribute(this.elementRef.nativeElement, 'style', css);
  }
}
