import { Component } from '@angular/core';
import { UntypedFormControl, FormGroupDirective, NgForm, Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { RemoteConfigurationService } from './remote-configuration.service';
import { initFlowbite } from 'flowbite';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private _formBuilder: UntypedFormBuilder, private remoteConfig: RemoteConfigurationService) {

  }
  ngOnInit(): void {
    initFlowbite();
  }
  $isMaintenance = this.remoteConfig.$signupMaintenance;
  centered = false;
  disabled = false;
  unbounded = false;
  firstFormGroup: UntypedFormGroup = this._formBuilder.group({firstCtrl: ['']});
  secondFormGroup: UntypedFormGroup = this._formBuilder.group({secondCtrl: ['']});

  radius: number = 0;
  color: string = "FF0000"
  title = 'blazebite-customer-signup';
  emailFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  matcher = new MyErrorStateMatcher();
}
