import { Component } from '@angular/core';
import { version } from '../../environments/version';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'bcs-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  version: string;
  production: string;
  constructor() {
    this.version = version;
    this.production = environment.production ? 'Production' : 'Development';
  }
 }
