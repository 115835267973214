<bcs-step>
    <div header>
        <h2>Let's answer some questions about venue.</h2>
    </div>
    <div bcsStepContent content>
        
        <label>Do you currently have Wi-Fi or internet access at your concession stands?</label>
        <mat-radio-group [(ngModel)]="internetAccess">
            <mat-radio-button value="true">Yes</mat-radio-button>
            <mat-radio-button value="false">No</mat-radio-button>
        </mat-radio-group>
        <label style="padding-bottom: 10px;">How many orders do you expect per hour during peak time?</label>
        <mat-form-field appearance="fill">

            <mat-label>Expected orders per hours?</mat-label>
            <mat-select [(value)]="selectedVolume">
                <mat-option [value]="option" *ngFor="let option of orderVolumes">{{ option }}</mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-raised-button matStepperNext bcsStepButton type="button" color="primary" [disabled]="!internetAccess || selectedVolume.length === 0">
            Continue
            <mat-icon bcsButtonIcon>arrow_forward_ios</mat-icon>
        </button>
    </div>
</bcs-step>