import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[bcsStepContent]'
})
export class StepContentDirective implements OnInit {

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }
  ngOnInit(): void {
    const css = `
      display: flex;
      flex-direction: column;
      justify-items: stretch;
      align-items: flex-start;
      max-width: 600px;
    `
    this.renderer.setAttribute(this.elementRef.nativeElement, 'style', css);
  }
}
