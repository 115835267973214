import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FieldsMatchValidatorService {
  getValidatorFn = (control1Name: string, control2Name: string, key = 'fieldsMatch'): ValidatorFn => {
    const validate: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
      const control1 = control.get(control1Name);
      const control2 = control.get(control2Name);
      return control1 && control2 && control1.value !== control2.value ? { [key]: true } : null;
    }
    return validate;
  }

}
