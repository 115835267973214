import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export class ProspectData {
  emailAddress: string;
  orderVolume: string;
  internetAvailability: boolean;
  venueName: string;
  ownerPhoneNumber: string;
  password: string;
  
  constructor(emailAddress: string, orderVolume: string, internetAvailability: boolean, venueName: string, ownerPhoneNumber: string, password: string) {
    this.emailAddress = emailAddress;
    this.orderVolume = orderVolume;
    this.internetAvailability = internetAvailability;
    this.venueName = venueName;
    this.ownerPhoneNumber = ownerPhoneNumber;
    this.password = password;
  }
}

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  newUserEmail: BehaviorSubject<string> = new BehaviorSubject<string>('');
  newUserPassword: BehaviorSubject<string> = new BehaviorSubject<string>('');
  orderVolume: BehaviorSubject<string> = new BehaviorSubject<string>('');
  internetAvailability: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  venueName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  ownerPhoneNumber: BehaviorSubject<string> = new BehaviorSubject<string>('');
  acceptedLicenseAgreement: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  password: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() { }

  setNewUserEmail(email: string) {
    this.newUserEmail.next(email);
  }

  setNewUserPassword(password: string) {
    this.newUserPassword.next(password);
  }

  setOrderVolume(volume: string) {
    this.orderVolume.next(volume);
  }
  
  setInternetAvailability(availability: boolean) {
    this.internetAvailability.next(availability);
  }
  
  setVenueName(name: string) {
    this.venueName.next(name);
  }
  
  setOwnerPhoneNumber(phoneNumber: string) {
    this.ownerPhoneNumber.next(phoneNumber);
  }

  setAcceptedLicenseAgreement(accepted: boolean) {
    this.acceptedLicenseAgreement.next(accepted);
  }

  setPassword(password: string) {
    this.password.next(password);
  }

  newProspectData(): ProspectData {
    return new ProspectData(this.newUserEmail.value, this.orderVolume.value, this.internetAvailability.value, this.venueName.value, this.ownerPhoneNumber.value, this.password.value);
  }
}
