import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[bcsStepButton]'
})

export class StepButtonDirective implements OnInit {
  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }
  ngOnInit(): void {
    const css = `
    margin-left: 16px;
  `
    this.renderer.setAttribute(this.elementRef.nativeElement, 'style', css);
  }
}
