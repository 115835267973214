import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { CreateCredentialFieldService } from '../shared/address-errors.service';
import { CreateCredentialFieldMessages } from '../shared/CreateCredentialFieldMessages';
import { AdminFunctionsService } from '../admin-functions.service';
import { SessionStorageService } from '../session-storage.service';
import { Observable, combineLatest, map, merge, mergeMap } from 'rxjs';

@Component({
  selector: 'bcs-venue',
  templateUrl: './venue.component.html',
  styleUrls: ['./venue.component.css']
})
export class VenueComponent implements OnInit {
  venueName: string = '';
  ownerPhoneNumber: string = '';
  emailValue: string = '';
  passwordValue: string = '';
  confirmPasswordValue: string = '';
  constructor(private functions: AdminFunctionsService, private storageManagement: SessionStorageService, private addressErrorsService: CreateCredentialFieldService, @Inject('REQUIRED') private REQUIRED: string) { }

  ngOnInit(): void {
    this.storageManagement
    .newUserEmail
    .subscribe(email => this.emailValue = email);
  }

  getErrorMessage(fieldControl: UntypedFormControl, controlName: keyof CreateCredentialFieldMessages | 'text') {
  
    if (controlName = 'text') {
      if (fieldControl.errors?.['required']) {
        return this.REQUIRED
      }
      if (fieldControl.errors?.['maxlength']) {
        return 'Venue must be no longer than 255 characters'
      }

    }
    return this.addressErrorsService.getErrorMessage(fieldControl, controlName as keyof CreateCredentialFieldMessages)
  }

  doPasswordsMatch() {
    return true;
    if(this.passwordValue.length === 0 || this.confirmPasswordValue.length === 0) return false;
    return this.passwordValue === this.confirmPasswordValue;
  }

  buttonDisabled() {
    return this.passwordValue.length === 0 || this.confirmPasswordValue.length === 0 || this.venueName.length === 0 || this.ownerPhoneNumber.length === 0;
  }

  createNewVenue() {
    this.storageManagement.setPassword(this.passwordValue);
    const data = this.storageManagement.newProspectData();
    console.log(`Data: ${JSON.stringify(data)}`);
    this.storageManagement.newUserEmail.pipe(
      mergeMap(email => this.functions.createNewUnverifiedUser(email, this.passwordValue, {
        venueName: this.venueName,
        ownerPhoneNumber: this.ownerPhoneNumber,
      })),
    ).subscribe(result => console.log(result));
  }
}
