import { Injectable, inject } from '@angular/core';
import { getApp } from 'firebase/app';
import { getFunctions, connectFunctionsEmulator, httpsCallable, HttpsCallableResult } from '@angular/fire/functions';
import { from, map, Observable } from 'rxjs';

export interface CheckUserResponse {
  isRegistered: Boolean
}

@Injectable({
  providedIn: 'root'
})
export class AdminFunctionsService {
  //private functions: Functions = inject(Functions);

  constructor() { }

  private createFunctions() {
    const functions = getFunctions(getApp());
    // Need this to point to local emulator
    // connectFunctionsEmulator(functions, 'localhost', 5001);
    return functions;
  }

  checkForUser(email: String): Observable<CheckUserResponse> {
    const functions = this.createFunctions();
    const checkByEmail = httpsCallable(functions, 'adminUsers-checkForExistingUser');
    return from(checkByEmail({ emailAddress: email }))
      .pipe(
        map(result => result.data as CheckUserResponse)
      )
  }

  createNewUnverifiedUser(email: String, password: String, venueData: any): Observable<HttpsCallableResult> {
    const functions = this.createFunctions();
    const createNewUnverifiedUser = httpsCallable(functions, 'adminUsers-createUnverifiedUser')
    return from(createNewUnverifiedUser({ emailAddress: email, password: password, venueData: venueData }));
  }

  createNewPaymentAccountIntegration(country: string, url: string) : Observable<HttpsCallableResult> {
    const functions = this.createFunctions();
    const createNewPaymentAccountIntegration = httpsCallable(functions, 'accountCreate-createPaymentAccountIntegration')
    return from(createNewPaymentAccountIntegration({ country, url }));
    
  }
}