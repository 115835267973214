import { Inject, Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CreateCredentialFieldMessages } from './CreateCredentialFieldMessages';

@Injectable({
  providedIn: 'root'
})

export class CreateCredentialFieldService {
  private errorMessages: CreateCredentialFieldMessages = {
    password: {
      required: 'You must enter a value',
      maxlength: 'Street address must be no longer than 255 characters'
    },
    confirmPassword: {
      required: 'You must enter a value',
      maxlength: 'City must be no longer than 255 characters'
    },
  }
  constructor(@Inject('REQUIRED') private REQUIRED: string) { }
  getErrorMessage(fieldControl: UntypedFormControl, controlName: keyof CreateCredentialFieldMessages): string {
    if (fieldControl.errors?.['required']) {
      return this.REQUIRED;
    }
    if (fieldControl.errors?.['maxlength']) {
      return this.errorMessages[controlName]['maxlength'];
      ;
    }
    return '';
  }
}