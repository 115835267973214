<bcs-step>
  <div header>
    <h2>Recommended Hardware</h2>
    <p>
      Blazebite at this time requires an Andriod tablet to support orders sent to your venue.
      Especially, for multip-person, high frequency orders we also recommend a printer to coincide
      with this.
    </p>
    <p>
      Blazebite will work with your own personal android tablet, but we will not be able to provide
      any support for your venue.
    </p>
    <p>
      Blazebite can provide for purchase out of the box software for your business within 3-5 business days.
    </p>
  </div>
  <div bcsStepContent content>
    <mat-checkbox bcsFormField (change)="onChange()">
      I agree
    </mat-checkbox>
    <div>
      <button mat-raised-button matStepperNext bcsStepButton type="button" color="primary" (click)="orderHardware()"
        [disabled]="!checked">
        Order Hardware
        <mat-icon bcsButtonIcon>arrow_forward_ios</mat-icon>
      </button>
      <button mat-raised-button matStepperNext bcsStepButton type="button" color="primary" [disabled]="!checked">
        skip
        <mat-icon bcsButtonIcon>arrow_forward_ios</mat-icon>
      </button>
    </div>
  </div>
</bcs-step>