import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from '../session-storage.service';

@Component({
  selector: 'bcs-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.css']
})
export class QuestionnaireComponent implements OnInit {
  internetAccess?: boolean;
  orderVolumes: string[] = ['', '1-10', '10-100', '100-1000'];
  selectedVolume: string = ''
  constructor(public storage: SessionStorageService) { }

  ngOnInit(): void {
  }

  updateOrderVolume(event: Event) {
    
    const target = event.target as HTMLSelectElement
    console.log(`selected volume: ${target.value}`);
    this.selectedVolume = target.value

    this.storage.setOrderVolume(target.value);
  }

}
