import { Injectable } from '@angular/core';
import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

@Injectable({
  providedIn: 'root'
})


export class FieldsMatchMatcherService {
  Matcher = class implements ErrorStateMatcher {
    key = '';
    constructor(key = 'fieldsMatch') {
      this.key = key;
    }
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      return control?.touched && (form?.form.errors?.[this.key] || control.errors) || false
    }
  }
  getMatcher(key = 'fieldsMatch'): ErrorStateMatcher {
    return new this.Matcher(key);
  }
}