import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[bcsFormField]'
})
export class FormFieldDirective implements OnInit {

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }
  ngOnInit(): void {
    const css = `
      margin: 0 16px 24px 16px;
    `
    this.renderer.setAttribute(this.elementRef.nativeElement, 'style', css);
  }
}
