import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomInputsModule } from './custom-inputs/custom-inputs.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { MaterialDesignModule } from './material-design/material-design.module';
import { StepComponent } from './step/step.component';
import { environment } from '../environments/environment';
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { provideRemoteConfig, getRemoteConfig } from '@angular/fire/remote-config';
import { provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './header/header.component';
import { StepperComponent } from './stepper/stepper.component';
import { UserEmailComponent } from './user-email/user-email.component';
import { LicenseAgreementComponent } from './license-agreement/license-agreement.component';
import { StepContentDirective } from './step/step-container.directive';
import { FormFieldDirective } from './step/form-field.directive';
import { CardHeaderDirective } from './step/card-header.directive';
import { StepButtonDirective } from './step/step-button.directive';
import { HardwareAgreementComponent } from './hardware-agreement/hardware-agreement.component';
import { VenueComponent } from './venue/venue.component';
import { FinishUpComponent } from './finish-up/finish-up.component';
import { SharedModule } from './shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { NgxMaskModule } from 'ngx-mask';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    StepComponent,
    LayoutComponent,
    HeaderComponent,
    StepComponent,
    StepperComponent,
    StepContentDirective,
    UserEmailComponent,
    LicenseAgreementComponent,
    FormFieldDirective,
    CardHeaderDirective,
    StepButtonDirective,
    HardwareAgreementComponent,
    VenueComponent,
    FinishUpComponent,
    QuestionnaireComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CustomInputsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialDesignModule,
    MatIconModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    SharedModule,
    NgxMaskModule.forRoot(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFunctions(() => getFunctions()),
    provideAnalytics(() => getAnalytics()),
    provideRemoteConfig(() => {
      const config = getRemoteConfig()
      config.fetchTimeMillis = 1000
      return config
    }),
    provideFirestore(() => getFirestore())
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: 'REQUIRED', useValue: 'You must enter a value.' },
    ScreenTrackingService, UserTrackingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
