import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { tap, map, filter, mergeMap } from 'rxjs';
import { AdminFunctionsService } from '../admin-functions.service';
import { MatStepper } from '@angular/material/stepper';
import { ErrorStateMatcher } from '@angular/material/core';
import { FieldsMatchMatcherService } from '../shared/fields-match-matcher.service';
import { UserEmailErrorsService } from '../shared/user-email-errors.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SessionStorageService } from '../session-storage.service';
import { environment } from '../../environments/environment';

export class EmailSetup {
  constructor(
    public email?: string,
    public confirmedEmail?: string
  ) { }
}

export class LoginErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'bcs-user-form',
  templateUrl: './user-email.component.html',
  styleUrls: ['./user-email.component.css'],
})
export class UserEmailComponent implements OnInit {
  loading = false;
  @Input() stepper!: MatStepper;
  email: string = '';
  confirmedEmail: string = '';
  emailErrorMessage: string = '';
  emailFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  matcher = new LoginErrorStateMatcher();
  isProduction: boolean = false;

  constructor(
    private sessionStorage: SessionStorageService,
    private service: AdminFunctionsService,
    private fieldsMatchMatcher: FieldsMatchMatcherService,
    private userEmailErrors: UserEmailErrorsService,
    private snackBar: MatSnackBar
  ) {


    const emailAddressExists = 'evan@blazebite.com';
    const emailAddressDoesNotExists = 'jeremy@schmitts.com';

    this.isProduction = environment.production;
    const exampleResponse = { isRegistered: true };

    // service.checkForUser(emailAddressExists)
    //   .pipe(tap(result => console.log(`User is ${result.isRegistered}`)))
    //   .subscribe();
  }

  ngOnInit(): void {
    //used in html template for password verification field errorStateMatcher
    this.matcher = this.fieldsMatchMatcher.getMatcher();
  }

  getErrorMessage(formControl: UntypedFormControl, controlName: string) {
    return this.userEmailErrors.getErrorMessage(formControl, controlName);
  }

  createSignup() {
    console.log(`Email: ${this.email}`);
    this.loading = true;
    if (this.email.length > 0) {
      this.service.checkForUser(this.email)
        .pipe(
          tap(result => console.log(`User is ${result.isRegistered}`)),
          map(result => result.isRegistered),
        )
        .pipe(tap(result => console.log(`Yo response here: ${JSON.stringify(result)}`)))
        .subscribe(
          result => {
            this.loading = false;
            this.sessionStorage.newUserEmail.next(this.email);
            const data = this.sessionStorage.newProspectData();
            console.log(`Data: ${JSON.stringify(data)}`);
            if (result) {
              this.openSnackBar();
            } else {
              this.stepper.next();  
            }
            
          },
          error => {
            this.loading = false;
            console.log(`Error: ${JSON.stringify(error)}`);
            this.openSnackBar();
          }
        );
    }
  }

  openSnackBar() {
    this.snackBar.open("Email already exists in our system.", "", {
      duration: 2000,
    });
  }
}
