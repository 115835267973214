// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'blazebite-dev',
    appId: '1:1058704097780:web:a999876c774c0d620139a1',
    databaseURL: 'https://blazebite-dev.firebaseio.com',
    storageBucket: 'blazebite-dev.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyAX9dHElK0atRGY4ImVEoVcVYD6WFIPHvI',
    authDomain: 'blazebite-dev.firebaseapp.com',
    messagingSenderId: '1058704097780',
    measurementId: 'G-SQEMEDFKMB',
  },
  production: false
};