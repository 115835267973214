import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonIconDirective } from './button-icon.directive';

@NgModule({
  declarations: [
    ButtonIconDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ButtonIconDirective
  ]
})
export class SharedModule { }
