import { Inject, Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ErrorService } from '../custom-inputs/shared/ErrorService';

@Injectable({
  providedIn: 'root'
})
export class PasswordErrorsService implements ErrorService {

  constructor(@Inject('REQUIRED') private REQUIRED: string) { }
  getErrorMessage(fieldControl: UntypedFormControl, controlName: string) {
    if (controlName === 'confirmedPassword') {
      if (fieldControl.parent?.hasError('fieldsMatch')) {
        return 'The passwords entered do not match.'
      }
    }
    if (fieldControl.hasError('required')) {
      return this.REQUIRED;
    }
    if (fieldControl.hasError('pattern')) {
      return 'Password must be 8 characters long containing 1 uppercase, lowercase, and $#@$!%*?&'
    }
    return fieldControl.hasError('password') ? 'Not a valid password' : '';
  }
}