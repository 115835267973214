import { Injectable } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

export class AddressValidatorService {

  getStreetAddressValidators(): ValidatorFn[] {
    return ([
      Validators.maxLength(255),
      Validators.required,
    ])
  }
  getCityValidators(): ValidatorFn[] {
    return ([
      Validators.maxLength(255),
      Validators.required,
    ])
  }
  getStateValidators(): ValidatorFn[] {
    return ([
      Validators.maxLength(255),
      Validators.required,
    ])
  }

}
