import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { CustomerFormService } from '../shared/customer-form.service';
import { FieldsMatchValidatorService } from '../shared/fields-match-validator.service';

@Component({
  selector: 'bcs-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css'],
})
export class StepperComponent implements OnInit {
  customerForm!: UntypedFormGroup;
  formService!: CustomerFormService
  isHardwareOrdered = false;
  emailValidator!: ValidatorFn;

  constructor(private customerFormService: CustomerFormService, private fieldsMatchValidator: FieldsMatchValidatorService) {
  }

  ngOnInit() {
    this.emailValidator = this.fieldsMatchValidator.getValidatorFn('email', 'confirmedEmail');
    this.customerForm = this.customerFormService.init();
  }

  get userForm(): UntypedFormGroup {
    return this.customerForm.get('userForm') as UntypedFormGroup;
  }
  get licenseAgreementForm(): UntypedFormGroup {
    return this.customerForm.get('licenseAgreementForm') as UntypedFormGroup;
  }
  get hardwareAgreementForm(): UntypedFormGroup {
    return this.customerForm.get('hardwareAgreementForm') as UntypedFormGroup;
  }
  get addressForm(): UntypedFormGroup {
    return this.customerForm.get('addressForm') as UntypedFormGroup;
  }
  get venueForm(): UntypedFormGroup {
    return this.customerForm.get('venueForm') as UntypedFormGroup;
  }
  get passwordForm(): UntypedFormGroup {
    return this.customerForm.get('passwordForm') as UntypedFormGroup;
  }
  orderHardware() {
    this.isHardwareOrdered = true;
  }
}
