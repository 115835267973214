import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SessionStorageService } from '../session-storage.service';


@Component({
  selector: 'bcs-license-agreement',
  templateUrl: './license-agreement.component.html',
  styleUrls: ['./license-agreement.component.css']
})
export class LicenseAgreementComponent {
  checked = false;
  constructor(private storage: SessionStorageService) {
    
  }

  onChange() {
    this.checked = !this.checked
    this.storage.setAcceptedLicenseAgreement(this.checked);
  }
  onContinue() {

  }
}
