<bcs-step>
  <div header>
    <h2>Let's create your account.</h2>
    <div *ngIf="!isProduction">
      <h4>(development)</h4>
    </div>
    <h3>Signing up for Blazebite is fast and free. No commitments or long-term contracts.</h3>
  </div>

  <div bcsStepContent content>
    <form>
      <mat-form-field bcsFormField [ngClass]="'form-field'">
        <mat-label>Email</mat-label>
        <input matInput placeholder="pat@example.com" [formControl]="emailFormControl" [(ngModel)]="email"
          [errorStateMatcher]="matcher">
        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="email !== confirmedEmail">
          Email and confirm email do not match.
        </mat-error>
      </mat-form-field>
      <mat-form-field bcsFormField [ngClass]="'form-field'">
        <mat-label>Confirm email</mat-label>
        <input matInput placeholder="pat@example.com" [formControl]="emailFormControl" [(ngModel)]="confirmedEmail"
          [errorStateMatcher]="matcher">
        <mat-error *ngIf="emailFormControl.hasError('confirmedEmail') && !emailFormControl.hasError('required')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="email !== confirmedEmail">
          Email and confirm email do not match.
        </mat-error>
      </mat-form-field>
      <div *ngIf="!loading else showProgress">
        <button mat-raised-button bcsStepButton type="button" color="primary" (click)="createSignup()"
          [disabled]="emailFormControl.invalid">
          Continue
          <mat-icon bcsButtonIcon>arrow_forward_ios</mat-icon>
        </button>
      </div>
      <ng-template #showProgress>
        <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
      </ng-template>
    </form>
  </div>

</bcs-step>