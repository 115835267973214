import { Component } from '@angular/core';
import { version } from '../../environments/version';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'bcs-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  version: string;
  environment: string;

  constructor() {
    this.version = version;
    this.environment = environment.production ? 'Production' : 'Development';
  }
}
