import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AddressValidatorService } from './address-validator.service';
import { FieldsMatchValidatorService } from './fields-match-validator.service';

@Injectable({
  providedIn: 'root'
})

export class CustomerFormService {
  form!: UntypedFormGroup;
  emailValidator!: ValidatorFn;
  required = 'You must enter a value.'
  errorMessages!: Object;


  constructor(
    private addressValidator: AddressValidatorService,
    private formBuilder: UntypedFormBuilder,
    private fieldsMatchValidator: FieldsMatchValidatorService) {
  }

  init(): UntypedFormGroup {
    this.emailValidator = this.fieldsMatchValidator.getValidatorFn('email', 'confirmedEmail');
    this.form = this.formBuilder.group({
      userForm: this.formBuilder.group({
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        confirmedEmail: new UntypedFormControl('', [Validators.required, Validators.email, Validators.maxLength(256)]),
      }, { validators: this.fieldsMatchValidator.getValidatorFn('email', 'confirmedEmail') as ValidatorFn }),
      licenseAgreementForm: this.formBuilder.group({
        licenseAgreement: new UntypedFormControl('', [Validators.requiredTrue])
      }),
      hardwareAgreementForm: this.formBuilder.group({
        hardwareAgreement: new UntypedFormControl('', [Validators.requiredTrue])
      }),
      addressForm: this.formBuilder.group({
        mailingStreetAddress: new UntypedFormControl('', [...this.addressValidator.getStreetAddressValidators()]),
        mailingCity: new UntypedFormControl('', [...this.addressValidator.getCityValidators()]),
        mailingState: new UntypedFormControl('', [...this.addressValidator.getStateValidators()]),
        billingStreetAddress: new UntypedFormControl('', [...this.addressValidator.getStreetAddressValidators()]),
        billingCity: new UntypedFormControl('', [...this.addressValidator.getCityValidators()]),
        billingState: new UntypedFormControl('', [...this.addressValidator.getStateValidators()]),
      }),
      venueForm: this.formBuilder.group({
        venueName: new UntypedFormControl('', [Validators.required, Validators.maxLength(255)]),
        venueStreetAddress: new UntypedFormControl('', [...this.addressValidator.getStreetAddressValidators()]),
        venueCity: new UntypedFormControl('', [...this.addressValidator.getCityValidators()]),
        venueState: new UntypedFormControl('', [...this.addressValidator.getStateValidators()])
      }),
      passwordForm: this.formBuilder.group({
        password: new UntypedFormControl('', [Validators.required, Validators.pattern(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&*?])[A-Za-z\d$#@$!%*?&].{7,}/)]),
        confirmedPassword: new UntypedFormControl('')
      }, { validators: this.fieldsMatchValidator.getValidatorFn('password', 'confirmedPassword') as ValidatorFn })
    })
    return this.form;
  }
}
