import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FieldsMatchMatcherService } from '../shared/fields-match-matcher.service';
import { PasswordErrorsService } from '../shared/password-errors.service';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'bcs-finish-up',
  templateUrl: './finish-up.component.html',
  styleUrls: ['./finish-up.component.css']
})
export class FinishUpComponent implements OnInit {
  matcher!: ErrorStateMatcher;
  errorsService!: PasswordErrorsService;

  constructor(
    private passwordErrorsService: PasswordErrorsService,
    private fieldsMatchMatcher: FieldsMatchMatcherService,
  ) { }

  ngOnInit(): void {
    this.matcher = this.fieldsMatchMatcher.getMatcher();
    this.errorsService = this.passwordErrorsService;
  }

}