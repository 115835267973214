<div>
  <mat-stepper linear orientation="vertical" #stepper>
    <mat-step>
      <ng-template matStepLabel>Set up your Account</ng-template>
      <bcs-user-form [stepper]="stepper"></bcs-user-form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Legal Agreement</ng-template>
      <bcs-license-agreement></bcs-license-agreement>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Questionnaire</ng-template>
      <bcs-questionnaire></bcs-questionnaire>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Venue Info</ng-template>
      <bcs-venue></bcs-venue>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Finish Up</ng-template>
      <bcs-finish-up></bcs-finish-up>
    </mat-step>
  </mat-stepper>
  <!-- <img class="right-image" src="assets/background.webp" alt="right-image"> -->
</div>