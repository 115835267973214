<div class="flex justify-center items-start min-h-screen bg-white">
  <div class="bg-white p-8 rounded-lg shadow-md max-w-2xl w-full">
    <h1 class="text-2xl font-bold text-gray-900 text-center">Let's create your account.</h1>
    <p class="text-gray-600 text-base text-center mb-4">
      Signing up for Blazebite is fast and easy. No commitments or long-term contracts required.
    </p>

    <form>
      <div class="mb-4">
        <label for="email" class="text-lg font-medium text-gray-700 mb-2 block">Email address</label>
        <input
          type="email"
          id="email"
          placeholder="you@example.com"
          class="input-primary text-lg"
          [(ngModel)]="email"
          name="email"
          (ngModelChange)="onFieldChange()"
        />
      </div>

      <div class="mb-4">
        <label for="password" class="text-lg font-medium text-gray-700 mb-2 block">Password</label>
        <input
          type="password"
          id="password"
          placeholder=""
          class="input-primary text-lg"
          [ngClass]="{'border-red-500': passwordsDoNotMatch}" 
          [(ngModel)]="password"
          name="password"
          (ngModelChange)="onFieldChange()"
        />
      </div>

      <div class="mb-4">
        <label for="confirmPassword" class="text-lg font-medium text-gray-700 mb-2 block">Confirm Password</label>
        <input
          type="password"
          id="confirmPassword"
          class="input-primary text-lg"
          [ngClass]="{'border border-red-500 bg-red-100': passwordsDoNotMatch}"
          [(ngModel)]="confirmPassword"
          name="confirmPassword"
          (ngModelChange)="onFieldChange()"
        />
        <p class="text-red-500 text-sm mt-1" *ngIf="passwordsDoNotMatch">
          Passwords do not match
        </p>
      </div>

      <h2 class="text-xl font-bold text-gray-900 mt-6">Tell us about your business</h2>
      <p class="text-gray-600 text-base mb-6">
        Together let’s tailor Blazebite for your business.
      </p>

      <div class="mb-4">
        <label for="business-name" class="sr-only">Business name</label>
        <input
          type="text"
          id="business-name"
          placeholder="Business name"
          class="input-primary text-base"
          [(ngModel)]="businessName"
          name="businessName"
          (ngModelChange)="onFieldChange()"
        />
      </div>

      <p class="text-gray-500 text-lg mb-6">
        Your business name is how we’ll identify you on emails, receipts, and messages to customers.
      </p>

      <div class="mb-4">
        <label for="locale" class="text-lg font-medium text-gray-700 mb-2 block">Locale</label>
        <select
          id="locale"
          class="input-primary text-base"
          [(ngModel)]="locale"
          name="locale"
          (ngModelChange)="onFieldChange()"
        >
          <option value="en-US">United States (English)</option>
          <option value="en-CA">Canada (English)</option>
          <option value="fr-CA">Canada (French)</option>
        </select>
      </div>

      <div class="my-8 flex items-center">
        <input
          type="checkbox"
          id="agree"
          class="mr-2 w-6 h-6 border-2 border-gray-500 rounded-md focus:ring-2 focus:ring-blue-500"
          [(ngModel)]="agreeTerms"
          name="agreeTerms"
          (ngModelChange)="onFieldChange()"
        />
        <label for="agree" class="text-gray-700 text-lg">
          I agree to Blazebite's
          <a href="https://www.blazebite.com/terms-and-conditions/" 
             class="text-blue-500 font-bold no-underline hover:underline" 
             target="_blank" 
             rel="noopener noreferrer">
            Terms
          </a>,
          <a href="https://www.blazebite.com/privacy-policy-2/" 
             class="text-blue-500 font-bold no-underline hover:underline" 
             target="_blank" 
             rel="noopener noreferrer">
            Privacy Policy
          </a>, and
          <a href="https://www.blazebite.com/blazebite-e-sign-consent/" 
             class="text-blue-500 font-bold no-underline hover:underline" 
             target="_blank" 
             rel="noopener noreferrer">
            E-Sign Consent
          </a>.
        </label>             
      </div>

      <div class="mt-4 text-center text-gray-500 text-sm">
        This site is protected by reCAPTCHA Enterprise and the Google
        <a 
          href="https://policies.google.com/privacy" 
          class="text-blue-500 font-bold no-underline hover:underline" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        and
        <a 
          href="https://policies.google.com/terms" 
          class="text-blue-500 font-bold no-underline hover:underline" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          Terms of Service
        </a>
        apply.
      </div>

      <button
        type="button"
        class="btn-primary w-full text-lg font-medium"
        (click)="submitForm()"
        [disabled]="!email || !password || !confirmPassword || !businessName || !agreeTerms || isVerifying"
      >
        <span *ngIf="!isVerifying">Continue</span>
        <span *ngIf="isVerifying">
          <svg
            class="animate-spin h-5 w-5 text-white inline-block mr-2"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          Verifying...
        </span>
      </button>

      <div class="text-center mt-4 text-gray-600">
        Already have a BlazeBite account?
        <a 
          href="https://portal.blazebite.com/" 
          class="text-blue-500 font-bold no-underline hover:underline">
          Sign in
        </a>.
      </div>
    </form>
  </div>
</div>
