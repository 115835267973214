import { Injectable, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

export class UserEmailErrorsService {
  constructor(@Inject('REQUIRED') private REQUIRED: string) { }
  getErrorMessage(fieldControl: UntypedFormControl, controlName: string) {
    if (controlName === "confirmedEmail") {
      if (fieldControl.parent?.hasError('fieldsMatch')) {
        return 'The email addresses entered do not match.'
      }
    }
    if (fieldControl.hasError('required')) {
      return this.REQUIRED;
    }
    if (fieldControl.hasError('email')) {
      return 'you must enter a valid email.'
    }
    return 'The input is invalid.';
  }
}