<bcs-step>
  <div header>
    <h2>License Agreement</h2>
    <p>"DISCLAIMER OF WARRANTIES: The Software
      is provided "as is" without warranty of any kind,
      either express or implied, including without
      limitation any warranty with respect to its
      fitness for any particular purpose. The
      Company shall not be liable for any indirect,
      incidental, consequential, or punitive damages
      arising out of or related to this agreement or
      the use of the Software, including but not
      limited to loss of profits, loss of business, or
      damage to reputation."</p>
  </div>
  <div bcsStepContent content>
    <mat-checkbox (change)="onChange()">
      I agree
    </mat-checkbox>
    <div>
      <button mat-raised-button matStepperNext bcsStepButton type="button" color="primary" [disabled]="!checked">
        Continue
        <mat-icon bcsButtonIcon>arrow_forward_ios</mat-icon>
      </button>
    </div>
  </div>
</bcs-step>