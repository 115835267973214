import { Component } from '@angular/core';
import { SessionStorageService } from '../session-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-venue-confirmation',
  templateUrl: './venue-confirmation.component.html',
  styleUrls: ['./venue-confirmation.component.css']
})
export class VenueConfirmationComponent {
  constructor(
    private sessionStorage: SessionStorageService,
    private router: Router
  ) { }

  onResetAndNavigate(event: Event) {
    event.preventDefault();
    this.sessionStorage.clearAllStorage();
    window.location.href = 'https://portal.blazebite.com/';
  }
}
