<mat-card>
  <mat-card-header>
    <div class="stepContent">
      <ng-content select="[header]">
      </ng-content>
    </div>
  </mat-card-header>
  <mat-card-content>
    <ng-content select="[content]"></ng-content>
  </mat-card-content>
</mat-card>