<!-- <div *ngIf="($isMaintenance | async)?.length === 0; else maintenance"> -->
  <!-- <app-main></app-main> -->
  <bcs-header></bcs-header>
    <main>
      <section>
        <bcs-stepper></bcs-stepper>
      </section>
    </main>
  <bcs-footer></bcs-footer>
<!-- </div>
 
<ng-template #maintenance>{{$isMaintenance | async}}</ng-template> -->