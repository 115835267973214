import { Injectable } from '@angular/core';
import { sign } from 'crypto';
import { getValue, getRemoteConfig, fetchAndActivate, fetchConfig } from "firebase/remote-config";
import { get } from 'http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class RemoteConfigurationService {
  $signupMaintenance: Observable<string> = new Observable<string>();
  constructor() {
    this.$signupMaintenance = new Observable<string>(observer => {
      fetchConfig(getRemoteConfig())
      .then(() => {
        const message = getValue(getRemoteConfig(), 'signupMaintenance').asString()
        console.log(`Signup: ${message}`);
        observer.next(message);
      });
      
    });

  }
}
