import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'bcs-hardware-agreement',
  templateUrl: './hardware-agreement.component.html',
  styleUrls: ['./hardware-agreement.component.css']
})
export class HardwareAgreementComponent implements OnInit {
  @Output() onHardwareOrder = new EventEmitter();
  checked = false;

  orderHardware() {
    this.onHardwareOrder.emit(true);
  }

  onChange() {
    this.checked = !this.checked
  }

  ngOnInit(): void {
  }

}
