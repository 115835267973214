import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[bcsCardHeader]'
})
export class CardHeaderDirective implements OnInit {

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }
  ngOnInit(): void {
    const css = `
      margin-left: 0px;
      background: blue;
    `
    this.renderer.setAttribute(this.elementRef.nativeElement, 'style', css);
  }
}
