<bcs-step>
  <div header>
    <h2>Let's get some contact information about your venue.</h2>
  </div>
  <div bcsStepContent content>
    <mat-form-field>
      <mat-label>Phone Number</mat-label>
      <input matInput placeholder="Owner Phone Number" [(ngModel)]="ownerPhoneNumber" mask="(000) 000-0000" required>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Venue Name</mat-label>
      <input matInput placeholder="Venue Name" [(ngModel)]="venueName" required>
    </mat-form-field>
  </div>
  <div bcsStepContent content>
    <div header>
      <h2>Also we need your password credentials</h2>
    </div>
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput placeholder="pat@example.com" [(ngModel)]="emailValue" disabled>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Password</mat-label>
      <input matInput placeholder="Password" [(ngModel)]="passwordValue" required type="password">
      <mat-hint class="mat-error" *ngIf="passwordValue !== confirmPasswordValue">Passwords do not match.</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Confirm Password</mat-label>
      <input matInput placeholder="Confirm Password" [(ngModel)]="confirmPasswordValue" required type="password">
      <mat-hint class="mat-error" *ngIf="passwordValue !== confirmPasswordValue">Passwords do not match.</mat-hint>
    </mat-form-field>
    <div>
      <button mat-raised-button matStepperNext bcsStepButton type="button" color="primary" (click)="createNewVenue()" [disabled]="buttonDisabled()">
        Submit
        <mat-icon bcsButtonIcon>arrow_forward_ios</mat-icon>
      </button>
    </div>
  </div>
</bcs-step>